@import '../../styles/mixins';
@import '../../styles/variables.module';

.index {
  padding-right: 16px;
  font-size: 1.2em;
  font-weight: 500;
}

.container {
  margin-bottom: 24px;
  display: flex;
  flex-wrap: nowrap;
  line-height: 40px;
}

.content {
  flex-grow: 1;
}

.input input,
.typeSelector {
  font-weight: 500;
  border-radius: 0;
}

.input {
  :global(.ant-input-group-addon) {
    border-radius: 0;
  }
  :global(.ant-input-affix-wrapper) {
    border-radius: 0;
  }
  &_error {
    :global {
      .ant-input-affix-wrapper {
        border-color: $dark-red !important;
      }
    }
  }
}

.typeSelector {
  min-width: 100px;
}

.actions {
  text-align: center;
  margin-left: 8px;
  min-width: 60px;
}

.button {
  vertical-align: 0px;
  @include transparent-element;
}

.Button {
  @include transparent-element;
}
