@import 'variables.module';

$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: 'only screen and (min-width : #{$small-screen-up})' !default;
$large-and-up: 'only screen and (min-width : #{$medium-screen-up})' !default;
$xlarge-and-up: 'only screen and (min-width : #{$large-screen-up})' !default;

$small-and-down: 'only screen and (max-width : #{$small-screen})' !default;
$medium-and-down: 'only screen and (max-width : #{$medium-screen})' !default;
$large-and-down: 'only screen and (max-width : #{$large-screen})' !default;

$medium-only: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})' !default;
$medium-and-large: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$large-screen})' !default;
$large-only: 'only screen and (min-width : #{$medium-screen-up}) and (max-width : #{$large-screen})' !default;

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

@mixin phone {
  @media #{$small-and-down} {
    @content;
  }
}

@mixin tablet {
  @media #{$medium-and-down} {
    @content;
  }
}

@mixin desktop {
  @media #{$medium-only} {
    @content;
  }
}

@mixin layout-padding {
  padding: $content-padding;

  @include tablet {
    padding: 1em;
  }
}

@mixin transparent-element {
  border: hidden;
  background-color: transparent;
  box-shadow: none;

  &:hover {
    background-color: transparent;
  }
}

@mixin hidden-element {
  visibility: hidden;
  opacity: 0;
  transition: opacity 100ms ease-in;
}

@mixin visible-element {
  visibility: visible;
  opacity: 1;
}

@mixin custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-color) transparent;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;

    &-track {
      background: transparent;
    }

    &-thumb {
      background-clip: padding-box;
      background-color: var(--scrollbar-color);
      border-radius: 6px;
    }

    &-corner {
      background: transparent;
    }
  }
}

@mixin flex-row-center-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
