@import '~@1upvision/scripts/lib/antd-theme';
@import './helpers';

$base-font-size: $font-size-base; // 64px
$primary: $primary-color; //  #413bb5
$white: #ffffff;
$dark-red: #ff2618;
$lightest-grey: #fafafa;
$lighter-grey: #e8e8e8;
$light-grey: #dcdcdc;
$grey: #9c9c9c;
$dark-grey: #555555;
$darker-grey: #333333;
$darkest-grey: #2e2e2e;
$lime-green: #32cd32;
$black: #000000;
$light-primary: lighten($primary, 45);
$box-shadow-color: rgba($primary, 0.15);
$box-shadow: -2px 1px 10px 0px rgba(0, 0, 0, 0.15);
$header-padding: 30px;
$header-height: $layout-header-height; // 64px
$content-padding: 15px $header-padding;
$primary-font-family: serif;
$large-font: 2rem;
$largest-font-size: 5rem;
$settings-container-max-width: 900px;
$primary-light-90: tint($primary-color, 90%);
$border: 1px solid $lighter-grey;

//noinspection CssInvalidPseudoSelector applies in Webstorm or other jetbrains ide
:export {
  primaryColor: $primary;
  greyColor: $grey;
  limeGreen: $lime-green;
  headerHeight: $header-height;
  white: $white;
  red: $dark-red;
  colors: {
    system: orange;
    visit: volcano;
    checkin: orange;
    checkout: orange;
    screening: orange;
    evacuation: orange;
    people: orange;
    invitation: orange;
    ams: volcano;
    people: orange;
  }
}
