.Root {
  padding: 10px 5px;
  border: 1px solid #e8e8e8;
  margin: 10px 0;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  &:first-child {
    margin-top: 10px;
  }

  span {
    font-weight: 400;
  }
}
