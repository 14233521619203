@import '../styles/variables.module';
@import '../styles/mixins';

.Root {
  box-shadow: $box-shadow;
  max-width: 100%;

  :global {
    .ant-drawer-body {
      padding: 0;
      height: 100%;
    }
    .ant-drawer-content-wrapper {
      max-width: 100%;
    }
  }
}

.Content {
  flex-direction: column;
  flex-wrap: unset;
  height: 100%;
}

.Header {
  padding: 0.8em 1.5em;
  border-bottom: 1px solid $lighter-grey;
  min-height: $header-height;
  max-height: $header-height;
}

.Title {
  margin: 0;
}

.Body {
  flex-grow: 1;
  overflow-y: auto;

  @include custom-scrollbar;
}

.Footer {
  padding: 0.8em;
  border-top: 1px solid $lighter-grey;
  margin-top: auto;
}

.Image {
  max-width: 100%;
  height: 150px;
  margin: auto;
  display: block;
  box-shadow: $box-shadow;
  border-radius: 0.7em;
  object-fit: cover;
}

.Button {
  @include transparent-element;
}

.Item {
  padding: 0.5em 1.5em;

  @include phone {
    padding: 0.5em 0.6em;
  }

  &__DetailContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__Value {
    overflow-wrap: break-word;
    display: inline-block;
  }

  &__CustomValueStyle {
    overflow-wrap: break-word;
    display: block;
  }

  &__Heading {
    color: $black;
    padding-right: 5px;
  }

  &:first-of-type {
    padding-top: 1em;
  }

  &:last-of-type {
    padding-bottom: 1em;
  }
}

.Section {
  border-bottom: 1px solid $lighter-grey;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-skeleton {
      padding: 1em;
    }
  }

  &__Title,
  :global(.title) {
    display: block;
    font-size: 1em;
    font-weight: 600;
    margin-top: 0;
  }

  &__Title {
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid $lighter-grey;
    background-color: $lightest-grey;
    display: flex;
    justify-content: space-between;
  }

  &:last-of-type {
    border-bottom-color: transparent;
  }
}
