.Loader {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 150px;

  &__Inline {
    display: inline;
  }
}
