.Item {
  width: calc(50% - 5px);
  text-align: center;
  padding: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:last-of-type {
    float: unset;
  }

  img {
    height: 130px;
    object-fit: cover;
    width: 100%;
  }

  &__Buttons {
    display: none;
    position: absolute;

    button {
      margin: 5px;
    }
  }

  &__SeeMore {
    position: absolute;
    display: flex;
    width: 186px;
    height: 130px;
    // max-width: 100%;
    object-fit: cover;
    color: white;
    cursor: pointer;
    opacity: 0.6;
    justify-content: center;
    align-items: center;
    background-color: black;
  }

  &:hover {
    .Item__Buttons {
      display: flex;
    }

    .Item__SeeMore {
      // display: flex;
      opacity: 0.8;
    }
  }
}

.Caption {
  margin-top: 5px;
}
