.OrganizationStatus {
  padding: 8px 4px !important;
  border-bottom: 1px solid #faa;
  text-align: center;
  :global {
    .ant-alert-message {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.Title {
  margin-bottom: 0 !important;
}
