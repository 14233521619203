@import '../styles/variables.module';

@import '../styles/mixins';

.Root {
  padding: 0 10px;
}
.Row {
  max-width: 900px;
  margin: auto;
  justify-content: space-between;
}
.Menu {
  :global {
    .ant-menu {
      background-color: transparent;
      text-align: right;
      display: block;
    }
    .ant-menu-horizontal > .ant-menu-item > a {
      display: inline;
    }
  }
}

.Heading {
  margin-top: 1em;
}

.Plan {
  width: 300px;
  margin: 15px 0;
}

.Skeleton {
  width: 100%;
  padding: 20px 20%;

  @include phone {
    padding: 5px;
  }
}

.Divider {
  border-color: $lighter-grey !important;
}
