@import '../styles/variables.module';
$handleSize: 20px;
$scaleLinesHeight: 16px;

.TimelineSelector {
  background-color: $white;
  padding: 0.7em $header-padding;
  display: flex;
  align-items: center;
  transition: opacity 0.3s;

  &__DateSelector {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-left: 1em;

    button {
      position: relative;
      z-index: 1;
    }
  }

  &__Slider {
    flex-grow: 1;
    padding: 0 2.5em;
    user-select: none;

    .ant-slider {
      &-handle {
        width: $handleSize;
        height: $handleSize;
        margin-top: $handleSize/-3;
        margin-left: $handleSize/-3;
      }

      &-dot {
        top: $scaleLinesHeight/-2.6;
        height: $scaleLinesHeight;
        border: 1px solid $light-grey;
        background-color: $lightest-grey;
        border-radius: 0.2em;
        width: 5px;
        margin-left: -2px;
      }

      &-mark-text {
        font-size: 12px;
      }
    }
  }

  &__DisabledMark {
    opacity: 0.3;
  }

  &__ModeSelector {
    display: inline-block;
    padding-right: 0.5em;
    border-radius: 0.3em;
    text-align: center;

    > * {
      display: inline-block;
    }
  }

  &__DatePicker {
    input {
      width: 20em;
      font-weight: 500;
    }
  }

  &__Mode {
    display: inline-block;
    font-weight: 500;
    transition: all 0.5s;
    text-decoration: underline;
    text-decoration-color: transparent;
    cursor: pointer;
    padding: 0 0.2em;

    &.selected {
      font-weight: 700;
    }

    &:hover {
      color: $primary;
      text-decoration-color: $primary;
    }
  }

  &__Now {
    margin-left: 0.7em;
  }

  &.disabled {
    pointer-events: none;

    > * {
      opacity: 0.5;
    }
  }
}
