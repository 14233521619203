@import '../../styles/variables.module';
@import '../../styles/mixins';

.Root {
  padding: 8px 0;
  height: $header-height;
}

.Submenu {
  margin-left: -8px;

  &__Title {
    pointer-events: none;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__Items {
    max-height: 50vh;
    overflow: auto;
    @include custom-scrollbar;
  }
  &__SpaceName {
    max-width: 220px;
    margin-right: 8px;
    overflow: hidden;
  }
}

.LoadMore {
  width: 100%;
  color: #413bb5;
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
  background-color: #e9e6f5;
  cursor: pointer;
  border: hidden;
}
