@import '../../styles/variables.module';

.Submenu {
  :global {
    .ant-menu-submenu-title {
      overflow: visible;
    }
  }
}

.Badge {
  :global {
    .ant-scroll-number {
      background-color: $primary;
      position: absolute;
    }
  }
}

.Text {
  margin-left: 10px;
}
