.Root {
  line-height: 1.3em;
  display: inline-block;
  vertical-align: middle;
  max-width: 71%;

  > * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__Title {
    font-size: 1.2em;
    font-weight: bold;
  }

  &__Subtitle {
    font-weight: 500;
  }
}
