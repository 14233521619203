@import '../styles/variables.module';

.Image {
  transition: opacity 0.5s;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 0 0.1em $light-grey;
  margin: auto;
  width: 100%;
  height: 100%;

  &.Loading {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
  }
}

.Content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
