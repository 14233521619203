@import '../styles/variables.module';
@import '../styles/mixins';

.Container {
  min-height: 100%;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 1px 2px #0001;
  .Logo {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    > img {
      margin-bottom: 1.2em;
    }
  }

  p {
    padding: 0 20px;
  }
}

.Content {
  max-width: 500px;
  text-align: center;
  width: 100%;
  padding: 3em 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.CustomLoginPageList {
  overflow: auto;
  border: 1px solid rgba(140, 140, 140, 0.35);
  max-height: 400px;
  @include tablet {
    max-height: 400px;
  }
  @include phone {
    max-height: 230px;
  }
}
