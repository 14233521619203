@import '../../../../styles/mixins';
@import '../../../../styles/variables.module';

.option {
  margin-bottom: 4px;
  display: block;

  input {
    border-style: hidden;
    border-bottom-style: solid;
    background: none;
    border-radius: 0;
  }

  :global {
    .ant-input-group-addon {
      border: hidden;
      background: none;
    }
    .ant-input-affix-wrapper {
      border-style: hidden;
      border-bottom-style: solid;
      background: none;
      border-radius: 0;
      box-shadow: none;
    }
  }
  &_error {
    :global {
      .ant-input-affix-wrapper {
        border-color: $dark-red !important;
      }
    }
  }
}

.deleteIcon {
  @include transparent-element;
}
