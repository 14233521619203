@import '../../styles/variables.module';
@import '../../styles/mixins';

.Menu {
  max-height: calc(100% - 48px - 56px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 8em;
  @include custom-scrollbar;

  :global {
    .new::after {
      position: relative;
      content: 'New';
      color: $dark-red;
      background-color: $white;
      padding: 2px;
      border-radius: 5px;
      top: -10px;
      left: 6px;
      font-size: 10px;
    }

    .ant-menu-light .new::after {
      background-color: $primary;
      color: $white;
    }
    li.ant-menu-submenu-inline {
      & > div.ant-menu-submenu-title {
        padding-left: 16px !important;
      }
    }
    .ant-menu-inline-collapsed .new::after {
      display: none;
    }
  }
}

.Container {
  height: 100%;
  box-shadow: $box-shadow-color 2px 0 6px;
  z-index: 10;

  :global {
    .ant-menu {
      background-color: inherit;
      border: hidden;
    }

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }
  }
}

.Actions {
  margin-top: auto;
  padding: 0.5em 0;
}

.Extras {
  position: absolute;
  width: 100%;
  bottom: 0;
}
:global {
  .ant-tooltip-inner .tooltiptext {
    color: $white;
  }
}

.LoadMore {
  display: flex;
  width: 100%;
  height: 85%;
  justify-content: center;
  align-items: center;
  color: #413bb5;
  background-color: #e9e6f5;
  &:hover {
    opacity: 0.8;
  }
}

.SidebarPopup {
  max-width: 400px;
}
