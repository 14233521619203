@import '../../../../styles/mixins';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  :global(.ant-select) {
    width: 100%;
  }

  :global(.ant-select-selection-item) {
    width: 0;
  }
}

.option {
  margin-bottom: 4px;
  display: block;

  input {
    border-style: hidden;
    border-bottom-style: solid;
    background: none;
    border-radius: 0;
  }

  :global {
    .ant-input-group-addon {
      border: hidden;
      background: none;
    }
  }
}

.deleteIcon {
  vertical-align: 0px;
  @include transparent-element;
}

.actions {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  align-self: flex-start;
}
