.Root {
  .Image {
    width: 100%;
    object-fit: cover;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0fdff;
  }

  :global {
    .slick-dots {
      bottom: 55px;
    }
  }
}

.Item {
  width: 100%;
  text-align: center;
  padding: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  &:last-of-type {
    float: unset;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__Buttons {
    display: none;
    position: absolute;

    button {
      margin: 5px;
      margin-bottom: 20px;
    }
  }

  &:hover {
    .Item__Buttons {
      display: flex;
    }
  }
}

.LoadMore {
  position: absolute;
  display: flex;
  width: 100%;
  height: 85%;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: black;
  opacity: 0.6;
  z-index: 4;

  &:hover {
    opacity: 0.8;
  }
}

.Caption {
  margin-top: 5px;
}

.PreviewSlider {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.ThumbnailSlider {
  margin-left: 10px;
  margin-right: 10px;
  // width: 339px;
}

.Carousel {
  margin-top: 15px;
}

.Title {
  text-align: center;
  padding: 5px;
}

.Slide {
  text-align: center;
  height: 60vh;
  padding: 5px 0;
}

.isIndexedStatusContainer {
  position: absolute;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  top: 1.25rem;
  left: 1.25rem;
  z-index: 10;
  cursor: pointer;

  button {
    background: none;
    border: hidden;
    display: flex;
    justify-content: center;

    &:hover {
      background: none;
    }
  }

  &__preview {
    top: 0;
    left: 0;
    transform: scale(0.5);
  }
}

.creationTimeContainer {
  position: absolute;
  top: 0rem;
  right: 0rem;
  z-index: 10;
  background: rgba(255, 255, 255, 0.7);
  padding: 0.5rem;
}
