@import '../../../styles/variables.module';

.root {
  border: 1px solid $light-grey;
  border-top-color: transparent;
  position: relative;
  top: -2px;
}

.container {
  > .option {
    margin: 0 4px;
    border-style: hidden;
    box-shadow: none;
    padding: 0 15px;

    &.selected {
      background: $lighter-grey;
    }
  }
}

.count {
  margin-left: 4px;
}

.options {
  background: $lightest-grey;
  padding: 12px 8px;
  border-top-style: hidden;
}

.addButton {
  background: transparent;
  display: block;
  margin-top: 8px;
}

.inclusion {
  border-top: 1px solid $lighter-grey;
  background: $lightest-grey;
  padding: 8px;

  :global(.ant-switch) {
    background-color: $primary;
  }

  &:empty {
    padding: 0;
    border: hidden;
  }
}

.description {
  line-height: 1.4em;
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 0.5em;

  > :first-child {
    margin-right: 8px;
  }
}
