@import './variables.module';
@import './mixins';
@import './animation';

* {
  --scrollbar-color: #{$grey};
  @include custom-scrollbar;
}

body {
  margin: 0;
  padding: 0;
}

:root {
  --scrollbar-color: #{$grey};
  background-color: $layout-body-background;
  @include custom-scrollbar;
}

#root {
  height: 100%;

  > div {
    height: 100%;
  }

  .ant-table-placeholder {
    z-index: 0;
  }

  .text-linethrough {
    text-decoration: line-through;
  }

  .Icon__Primary--RoundBorder {
    font-size: 100px;
    color: $primary;
    border: 5px solid $primary;
    border-radius: 50%;
  }
}

.ant {
  &-layout,
  &-layout-content {
    height: 100%;
  }

  &-btn {
    &-sm {
      .anticon {
        font-size: 12px;
      }
    }
  }

  &-avatar {
    img {
      object-fit: cover;
    }
  }
}

ul.ant-skeleton-paragraph {
  padding: 0;
}

.MediaPlaceholder {
  background-image: url('../assets/images/image-placeholder.svg');
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.slick-slide {
  position: relative;
}
.slick-prev:before,
.slick-next:before {
  color: #413bb5;
}

.ant-form .ant-form-item .ant-form-item-label > label {
  height: auto;
  white-space: pre-wrap;
}
.textCapitalize {
  text-transform: capitalize;
}

.leaflet-div-icon {
  background: transparent !important;
  border: hidden !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}
