@import '../../styles/variables.module';
@import '../../styles/mixins';

.Root {
  padding: 8px 0;
  margin-top: auto;
}

.Submenu {
  margin-left: -8px;
}
